import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Account } from '../../../../../auth/models/account.model';
import { Company, companyCan } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { Invoice, InvoiceCoverage } from '../../models/invoice.model';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'ag-invoice-actions',
  templateUrl: './invoice-actions.component.html',
  styleUrls: ['./invoice-actions.component.scss']
})
export class InvoiceActionsComponent implements OnInit, OnDestroy {

  @ViewChild('coverageModal', { static: true }) private readonly coverageModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public invoice: Invoice;
  @Input() public container: string;
  @Input() public right: boolean;

  public bartersEnabled: boolean;
  public canCreateBarter: boolean;
  public concepts: string[] = [
    'Otro',
    'Tarjeta de crédito',
    'Cheque',
    'Efectivo'
  ];
  public coverage: InvoiceCoverage[];
  public newCoverage: InvoiceCoverage;
  public otherCoverage: number;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private account: Account;
  private companyCan: { [action: string]: boolean };
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private companyService: CompanyService,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      this.account = account;

      this.checkSettings();
    }));
  }

  private checkSettings(): void {
    this.bartersEnabled = this.company.hasModule('barters') &&
      (this.company.hasModule('market') || this.company.hasModule('my-network'));

    this.companyCan = companyCan(this.company);

    this.canCreateBarter = this.account.is.commercial &&
      this.companyCan.createBarter; // Grain elevatorss, Brokers, Distributors or Suppliers only
  }

  public editCoverage(): void {
    this.newCoverage = new InvoiceCoverage({
      concept: this.concepts[0]
    });
    this.loadCoverage();
    this.openModal(this.coverageModal);
  }

  private updateOtherCoverage(): void {
    this.otherCoverage = undefined;

    const coverageTotal = this.coverage.reduce((accumulator, curValue) => {
      return accumulator + curValue.amount;
    }, 0);

    if (this.invoice.settled > coverageTotal) {
      this.otherCoverage = this.invoice.settled - coverageTotal;
    }
  }

  public addCoverage(form: NgForm): void {
    this.processing = true;
    this.subscriptions.push(this.invoiceService.createInvoicePayments(this.company.id, this.invoice.id, this.newCoverage).subscribe(payment => {
      form.resetForm();
      this.coverage.push(payment);
      this.newCoverage = new InvoiceCoverage({
        concept: this.concepts[0]
      });
      this.invoice.settled = this.invoice.settled + payment.amount;
      this.updateOtherCoverage();
      this.processing = false;
    }));
  }

  public removeCoverage(coverage: InvoiceCoverage): void {
    this.processing = true;
    this.subscriptions.push(this.invoiceService.deleteInvoicePayments(this.company.id, this.invoice.id, coverage).subscribe(response => {
      this.coverage = this.coverage.filter(c => c.id !== coverage.id);
      this.invoice.settled = this.invoice.settled - coverage.amount;
      this.updateOtherCoverage();
      this.processing = false;
    }));
  }

  private loadCoverage(): void {
    this.subscriptions.push(this.invoiceService.getInvoicePayments(this.company.id, this.invoice.id).subscribe(payments => {
      this.coverage = payments;
      this.updateOtherCoverage();
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
      class: c
    });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
