<contract-modal #contractModal [company]="company"></contract-modal>

<samp [tooltip]="contract.warnings?.length ? warnTemplate : null" [adaptivePosition]="false">
  <a (click)="contractModal.show(contract)">{{ contract.reference }}</a>
  <span *ngIf="contract.warnings?.length"
    class="differences material-symbols-rounded text-warning no-select hidden-xs">warning</span>
  <ng-template #warnTemplate>
    <div class="text-left">
      <p class="small">{{ 'IMPORTER.WARNING.TITLE'|translate }}</p>
      <span *ngFor="let d of contract.warnings; let last = last">
        {{ 'IMPORTER.WARNING.'+d|translate }}
        <ng-container *ngIf="!last"> ,</ng-container>
      </span>
    </div>
  </ng-template>
</samp>
