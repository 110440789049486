import { Component, Input } from '@angular/core';
import { Company } from '../../../../../models/company.model';
import { FixationRequest } from '../../models/fixation-request.model';

@Component({
  selector: 'fixation-request-history',
  templateUrl: './fixation-request-history.component.html',
  styleUrls: ['./fixation-request-history.component.scss']
})
export class FixationRequestHistoryComponent {

  @Input() public requests: FixationRequest[];
  @Input() public company: Company;
  @Input() public compact: boolean = false;

  constructor() { }

}
