import { Component, Input } from '@angular/core';
import { Company } from '../../../../../models/company.model';
import { Contract } from '../../../contracts/models/contract.model';

@Component({
  selector: 'contract-link-info',
  templateUrl: './contract-link-info.component.html',
  styleUrls: ['./contract-link-info.component.scss']
})
export class ContractLinkInfoComponent {

  @Input() public contract: Contract;
  @Input() public company: Company;

  constructor() { }

}
