<contract-modal #contractModal
[company]="company"></contract-modal>

<waybill-modal #waybillModal
[company]="company"></waybill-modal>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table table-condensed" sortable #tableSortable="tableSortable" [variableInUrl]="!predefinedFilters"
        AgMultiselection [(ngModel)]="selected">
        <thead>
          <tr class="tr-align-center">
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              {{ "GLOBAL.DATE" | translate }}
              <column-filter *ngIf="!predefinedFilters" type="date" alignment="left" [filters]="filters"
                key="filters[date]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="waybill_number" *ngIf="!hideColumns.includes('waybill_number')">
              {{ "TRUCKS.WAYBILL" | translate }}
              <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[waybill_number]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="ctg" *ngIf="!hideColumns.includes('ctg')">
              <abbr [tooltip]="'SLOTS.DETAILS_MODAL.CTG' | translate" title [adaptivePosition]="false">{{
                "SLOTS.DETAILS_MODAL.CTG_ABBR" | translate }}</abbr>
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[ctg]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="product.name" *ngIf="!hideColumns.includes('product')">
              {{ "GLOBAL.PRODUCT" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                key="filters[product.name]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <!-- Actors -->
            <th class="dlb" sortBy="actors.holder" *ngIf="!hideColumns.includes('actors.holder')">
              {{ "TRUCKS.ACTORS.HOLDER" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.holder]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.intermediary" *ngIf="!hideColumns.includes('actors.intermediary')">
              {{ "TRUCKS.ACTORS.INTERMEDIARY" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.intermediary]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.commercial_sender"
              *ngIf="!hideColumns.includes('actors.commercial_sender')">
              {{ "TRUCKS.ACTORS.COMMERCIAL_SENDER" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.commercial_sender]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.buying_broker" *ngIf="!hideColumns.includes('actors.buying_broker')">
              {{ "TRUCKS.ACTORS.BUYING_BROKER" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.buying_broker]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.selling_broker" *ngIf="!hideColumns.includes('actors.selling_broker')">
              {{ "TRUCKS.ACTORS.SELLING_BROKER" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.selling_broker]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.representative" *ngIf="!hideColumns.includes('actors.representative')">
              {{ "TRUCKS.ACTORS.REPRESENTATIVE" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.representative]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.destination" *ngIf="!hideColumns.includes('actors.destination')">
              {{ "TRUCKS.ACTORS.DESTINATION" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.destination]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="actors.receiver" *ngIf="!hideColumns.includes('actors.receiver')">
              {{ "TRUCKS.ACTORS.RECEIVER" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[actors.receiver]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <!-- End Actors -->
            <th class="dlb" sortBy="origin.location.name" *ngIf="!hideColumns.includes('origin.location')">
              {{ "GLOBAL.ORIGIN" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[origin.location.name]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th class="sap hidden-xs hidden-sm" *ngIf="!(
              hideColumns.includes('origin.location') ||
              hideColumns.includes('destination.location')
            )"></th>
            <th sortBy="destination.location.name" *ngIf="!hideColumns.includes('destination.location')">
              {{ "TRUCKS.DESTINATION" | translate }}
              <!-- <column-filter *ngIf="!predefinedFilters" type="string" alignment="center" [filters]="filters"
                key="filters[destination.location.name]" (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr tooltip="{{ 'GLOBAL.SOURCE'|translate }}"
              container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr class="tr-align-center"
          *ngFor="let waybill of waybills">
            <td class="sap micro" *ngIf="!hideColumns.includes('date')">
              <date-md [date]="waybill.date"></date-md>
            </td>
            <td class="small" *ngIf="!hideColumns.includes('waybill_number')">
              <samp><a (click)="waybillModal.show(waybill.id)">{{ waybill.waybill_number }}</a></samp>
            </td>
            <td class="small" *ngIf="!hideColumns.includes('ctg')">
              <samp *ngIf="waybill.ctg; else none">{{ waybill.ctg }}</samp>
            </td>
            <td class="no-vertical-padding" *ngIf="!hideColumns.includes('product')">
              {{ waybill.product.name }}
            </td>
            <!-- Actors -->
            <td class="sap actor dlb" *ngIf="!hideColumns.includes('actors.holder')">
              <company-info *ngIf="waybill.actors.holder; else none" [company]="waybill.actors.holder" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.intermediary')">
              <company-info *ngIf="waybill.actors.intermediary; else none" [company]="waybill.actors.intermediary" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.commercial_sender')">
              <company-info *ngIf="waybill.actors.commercial_sender; else none" [company]="waybill.actors.commercial_sender" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.buying_broker')">
              <company-info *ngIf="waybill.actors.buying_broker; else none" [company]="waybill.actors.buying_broker" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.selling_broker')">
              <company-info *ngIf="waybill.actors.selling_broker; else none" [company]="waybill.actors.selling_broker" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.representative')">
              <company-info *ngIf="waybill.actors.representative; else none" [company]="waybill.actors.representative" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.destination')">
              <company-info *ngIf="waybill.actors.destination; else none" [company]="waybill.actors.destination" [activity]="false"></company-info>
            </td>
            <td class="sap actor" *ngIf="!hideColumns.includes('actors.receiver')">
              <company-info *ngIf="waybill.actors.receiver; else none" [company]="waybill.actors.receiver" [activity]="false"></company-info>
            </td>
            <!-- End Actors -->
            <td class="dlb small" *ngIf="!hideColumns.includes('origin.location')">
              <ng-container *ngIf="waybill.origin.location?.name; else none">{{ waybill.origin.location.name }}</ng-container>
            </td>
            <td class="sap micro hidden-xs hidden-sm" *ngIf="!(
              hideColumns.includes('origin.location') ||
              hideColumns.includes('destination.location')
            )">
              <i class="material-symbols-rounded">arrow_right_alt</i>
            </td>
            <td class="small" *ngIf="!hideColumns.includes('destination.location')">
              <ng-container *ngIf="waybill.destination.location?.name; else none">{{ waybill.destination.location.name }}</ng-container>
            </td>
            <td *ngIf="!hideColumns.includes('source')" class="sap">
              <span *ngIf="waybill.import_channel_id; let source"
              class="micro text-muted">{{ 'IMPORTER.SOURCE.'+source|translate }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- No data -->
    <div class="no-data" *ngIf="waybills && waybills.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'WAYBILLS.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>