import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgPipesModule, OrderByPipe } from 'ngx-pipes';

import { DiffMatchPatchModule } from '../../../ui/modules/ng-diff-match-patch/diffMatchPatch.module';
import { UiModule } from '../../../ui/ui.module';
import { AddFutureModalComponent } from '../../components/add-future-modal/add-future-modal.component';
import { ContractService } from '../contracts/services/contract.service';
import { SharedModule } from '../shared/shared.module';
import { ClauseTitleInputComponent } from './components/clause-title-input/clause-title-input.component';
import { ContractApplicationsTableComponent } from './components/contract-applications-table/contract-applications-table.component';
import { ContractClausesFormComponent } from './components/contract-clauses-form/contract-clauses-form.component';
import { ContractLinkInfoComponent } from './components/contract-link-info/contract-link-info.component';
import { CpeTableComponent } from './components/cpe-table/cpe-table.component';
import { FixationRequestHistoryComponent } from './components/fixation-request-history/fixation-request-history.component';
import { FixationRequestTableComponent } from './components/fixation-request-table/fixation-request-table.component';
import { FixationsTableComponent } from './components/fixations-table/fixations-table.component';
import { ImportContractsComponent } from './components/import-contracts/import-contracts.component';
import { ImportedDataComponent } from './components/imported-data/imported-data.component';
import { ImporterComponent } from './components/importer/importer.component';
import { InvoiceActionsComponent } from './components/invoice-actions/invoice-actions.component';
import { InvoicesTableComponent } from './components/invoices-table/invoices-table.component';
import { ManageRequestFixationModalComponent } from './components/manage-request-fixation-modal/manage-request-fixation-modal.component';
import { WaybillsTableComponent } from './components/waybills-table/waybills-table.component';
import { AfipService } from './services/afip.service';
import { CartaPorteElectronicaService } from './services/carta-porte-electronica.service';
import { FixationRequestService } from './services/fixation-request.service';
import { ImporterService } from './services/importer.service';
import { InvoiceService } from './services/invoice.service';
import { UnloadService } from './services/unload.service';
import { WaybillService } from './services/waybill.service';

@NgModule({
  declarations: [
    AddFutureModalComponent,
    ClauseTitleInputComponent,
    ContractClausesFormComponent,
    ContractLinkInfoComponent,
    CpeTableComponent,
    FixationRequestHistoryComponent,
    FixationRequestTableComponent,
    FixationsTableComponent,
    ImportedDataComponent,
    ImporterComponent,
    InvoicesTableComponent,
    InvoiceActionsComponent,
    ManageRequestFixationModalComponent,
    WaybillsTableComponent,
    ContractApplicationsTableComponent,
    ImportContractsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UiModule,
    NgChartsModule,
    NgxJsonViewerModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DiffMatchPatchModule
  ],
  providers: [
    AfipService,
    CartaPorteElectronicaService,
    ContractService,
    FixationRequestService,
    ImporterService,
    InvoiceService,
    UnloadService,
    WaybillService,
    TranslateService,
    OrderByPipe,
  ]
})
export class ImportedDataModule { }
