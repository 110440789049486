<cpe-modal #cpeModal [company]="company"></cpe-modal>
<ag-file-preview #filePreviewer></ag-file-preview>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table table-condensed" sortable #tableSortable="tableSortable" [variableInUrl]="!predefinedFilters"
        AgMultiselection [(ngModel)]="selected">
        <thead>
          <tr class="tr-align-center">
            <th class="sap" sortBy="fecha_emision" *ngIf="!hideColumns.includes('fecha_emision')">
              {{ "GLOBAL.DATE" | translate }}
            </th>
            <th class="sap" sortBy="fecha_inicio_estado" *ngIf="!hideColumns.includes('fecha_inicio_estado')">
              {{ "TRUCKS.UNLOADED" | translate }}
            </th>
            <th class="sap" sortBy="numero_ctg" *ngIf="!hideColumns.includes('numero_ctg')">
              <abbr [tooltip]="'SLOTS.DETAILS_MODAL.CTG' | translate" container="body" title [adaptivePosition]="false">{{
                "SLOTS.DETAILS_MODAL.CTG_ABBR" | translate }}</abbr>
              <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
              key="filters[numero_ctg]" (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="sap" sortBy="nro_orden" *ngIf="!hideColumns.includes('nro_orden')">
              {{ "TRUCKS.WAYBILL" | translate }}
            </th>
            <th sortBy="product.name" *ngIf="!hideColumns.includes('product')">
              {{ "GLOBAL.PRODUCT" | translate }}
            </th>
            <th *ngIf="!hideColumns.includes('codigo_turno')">
              {{ "SLOTS.ID" | translate }}
            </th>
            <th class="dlb" sortBy="origen.provincia" *ngIf="!hideColumns.includes('origen.provincia')">
              {{ "GLOBAL.ORIGIN" | translate }}
            </th>
            <th class="sap hidden-xs hidden-sm" *ngIf="!(
                          hideColumns.includes('origen.provincia') ||
                          hideColumns.includes('destino.provincia')
                      )"></th>
            <th class="drb" sortBy="destino.provincia" *ngIf="!hideColumns.includes('destino.provincia')">
              {{ "TRUCKS.DESTINATION" | translate }}
            </th>
            <th sortBy="observaciones" *ngIf="!hideColumns.includes('observaciones')">
              {{ "GLOBAL.OBSERVATIONS" | translate }}
            </th>
            <th class="sap"></th>
            <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr tooltip="{{ 'GLOBAL.SOURCE'|translate }}"
              container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr class="tr-align-center" *ngFor="let cpeItem of cartasDePorteElectronicas">
            <td class="sap small" *ngIf="!hideColumns.includes('fecha_emision')">
              <ag-timestamp [date]="cpeItem.fecha_emision"></ag-timestamp>
            </td>
            <td class="sap small" *ngIf="!hideColumns.includes('fecha_inicio_estado')">
              <ag-timestamp [date]="cpeItem.fecha_inicio_estado"></ag-timestamp>
            </td>
            <td class="sap small" *ngIf="!hideColumns.includes('numero_ctg')">
              <samp><a (click)="cpeModal.show(cpeItem.numero_ctg)">{{ cpeItem.numero_ctg }}</a></samp>
            </td>
            <td class="sap small" *ngIf="!hideColumns.includes('nro_orden')">
              <samp *ngIf="cpeItem.nro_orden; else none">{{ cpeItem.numero }}</samp>
            </td>
            <td class="no-vertical-padding" *ngIf="!hideColumns.includes('product')">
              <span *ngIf="cpeItem.product; else none">{{ cpeItem.product.name }}</span>
            </td>
            <td class="small sap" *ngIf="!hideColumns.includes('codigo_turno')">
              <code *ngIf="cpeItem.transporte?.codigo_turno; else none">{{ cpeItem.transporte.codigo_turno }}</code>
            </td>
            <td class="dlb small" *ngIf="!hideColumns.includes('origen.provincia')">
              <place-info *ngIf="cpeItem.origen; else none" [placeInfo]="cpeItem.origen"></place-info>
            </td>
            <td class="sap micro hidden-xs hidden-sm" *ngIf="!(
                      hideColumns.includes('origen.provincia') ||
                      hideColumns.includes('destino.provincia')
                      )">
              <i class="material-symbols-rounded">arrow_right_alt</i>
            </td>
            <td class="drb small" *ngIf="!hideColumns.includes('destino.provincia')">
              <place-info *ngIf="cpeItem.destino; else none" [placeInfo]="cpeItem.destino"></place-info>
            </td>
            <td class="small" *ngIf="!hideColumns.includes('observaciones')">
              <span *ngIf="cpeItem.observaciones; else none">{{ cpeItem.observaciones }}</span>
            </td>
            <td class="sap">
              <button type="button"
              class="btn btn-link btn-xs" title="{{ 'ORDER_FORM.PREVIEW' | translate }}"
              (click)="preview(cpeItem)"><i class="material-symbols-rounded">picture_as_pdf</i></button>
            </td>
            <td *ngIf="!hideColumns.includes('source')" class="sap">
              <span *ngIf="cpeItem.import_channel_id; let source" class="micro text-muted">{{
                'IMPORTER.SOURCE.'+source|translate }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- No data -->
    <div class="no-data" *ngIf="cartasDePorteElectronicas?.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'CPE.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
