<div class="btn-group" dropdown [container]="container" placement="{{ right ? 'bottom right' : 'bottom left'}}"
*ngIf="invoice">
  <button id="button-ia" dropdownToggle type="button" class="btn btn-link btn-xs dropdown-toggle"
  aria-controls="dropdown-ia"><i class="material-symbols-rounded">more_vert</i></button>
  <ul id="dropdown-ia" *dropdownMenu class="dropdown-menu"
  [class.dropdown-menu-right]="right"
  role="menu"
  aria-labelledby="button-ia">
    <li role="menuitem">
      <a class="dropdown-item"
      [class.disabled]="invoice.isSettled || invoice.balance <= 0 || !bartersEnabled || !canCreateBarter"
      [routerLink]="['/company', company.id, 'create-barter', invoice.id]">{{ 'INVOICES.COVER_BARTER'|translate }}</a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item"
      [class.disabled]="!account.is.commercial"
      (click)="editCoverage()">{{ 'INVOICES.EDIT_COVERAGE'|translate }}</a>
    </li>
  </ul>
</div>

<ng-template #coverageModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ 'INVOICES.EDIT_COVERAGE'|translate }}: <samp>{{ invoice?.reference| mask: '0000-00000000' }}</samp>
    </h4>
    <div *ngIf="invoice">
      <span *ngIf="invoice.isSettled" class="ag-label label-success"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> {{ 'INVOICES.COVERED'|translate }}</span>
      <span *ngFor="let label of invoice.labels"
      class="ag-label" [style.backgroundColor]="label.color">{{ label.name }}</span>
    </div>
  </div>
  <div class="modal-body" id="coverageDrop">
    <div class="loading" *ngIf="!coverage">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <ng-container *ngIf="coverage">
      <div class="row">
        <div class="col-xs-12">
          <table class="table">
            <tbody>
              <tr class="active">
                <td><b>{{ 'GLOBAL.TOTAL'|translate }}</b></td>
                <td class="text-right"><price [unit]="invoice.unit.name" [value]="invoice.balance"></price></td>
                <td></td>
              </tr>
              <tr *ngIf="otherCoverage">
                <td>{{ 'COMPANY.BARTERS'|translate }}</td>
                <td class="text-right"><price [unit]="invoice.unit.name" [value]="-otherCoverage"></price></td>
                <td></td>
              </tr>
              <tr *ngFor="let item of coverage">
                <td>{{ item.concept }}</td>
                <td class="text-right"><price [unit]="invoice.unit.name" [value]="-item.amount"></price></td>
                <td class="sap text-center">
                  <a title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
                  [class.disabled]="processing"
                  (onConfirm)="removeCoverage(item)"
                  [confirm-options]="{
                    action: 'GLOBAL.YES_DELETE'|translate
                  }">
                    <i class="material-symbols-rounded">delete</i>
                  </a>
                </td>
              </tr>
              <tr class="active">
                <td><b>{{ 'INVOICES.COVERED_TOTAL'|translate }}</b></td>
                <td class="text-right"><price [unit]="invoice.unit.name" [value]="invoice.settled || 0"></price></td>
                <td class="sap text-center">
                  <progress-circle
                  *ngIf="invoice.settled"
                  label="{{ 'INVOICES.COVERAGE'|translate }}"
                  [progress]="invoice.settled / invoice.balance"></progress-circle>  
                </td>
              </tr>
              <tr *ngIf="invoice.balance - (invoice.settled || 0) > 0"
              class="danger">
                <td class="text-danger"><b>{{ 'DASHBOARDS.PENDING'|translate }}</b></td>
                <td class="text-right text-danger"><price [unit]="invoice.unit.name" [value]="invoice.balance - (invoice.settled || 0)"></price></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ng-container *ngIf="!invoice.isSettled">
        <div class="row">
          <div class="col-xs-12">
            <p class="small text-muted">{{ 'INVOICES.ADD_COVERAGE'|translate }}</p>
          </div>
        </div>
        <div class="row">
          <form (ngSubmit)="addCoverageForm.valid && addCoverage(addCoverageForm)" #addCoverageForm="ngForm">
            <div class="col-xs-5">
              <div class="form-group" hasError>
                <label class="control-label">{{ 'INVOICES.CONCEPT'|translate }}</label>
                <select class="form-control"
                name="concept"
                [(ngModel)]="newCoverage.concept"
                required
                [disabled]="processing">
                  <option [ngValue]="undefined" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
                  <option *ngFor="let c of concepts" [ngValue]="c">{{ c }}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-4">
              <div class="form-group" hasError>
                <label class="control-label">{{ 'BARTERS.AMOUNT'|translate }}</label>
                <input type="number" class="form-control"
                name="amount"
                [(ngModel)]="newCoverage.amount"
                required
                placeholder="0.00"
                step="0.01"
                min="0.01"
                [max]="invoice.balance - (invoice.settled || 0)"
                [disabled]="processing">
              </div>
            </div>
            <div class="col-xs-3">
              <div class="form-group">
                <label class="control-label">&nbsp;</label>
                <button class="btn btn-block btn-default" [disabled]="processing"
                type="submit"><i class="material-symbols-rounded">add</i> {{ 'GLOBAL.ADD'|translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
      <attachments
      entity="invoice"
      [entity-id]="invoice.id"
      [company]="company"
      drop-area="#coverageDrop"></attachments>
    </ng-container>
  </div>
</ng-template>