<div class="fixations-history" *ngIf="requests">
  <!-- En el caso de existir más de una solicitud de fijación
          se visualizaran en este modal cómo historial de las partes
          (la última será la fijación mostrada en el modal).

          Se verá el volumen y el precio que se solicitó
          en esa etapa de fijación.
      -->
  <div class="request-info flex-row center" *ngFor="let request of requests">
    <ng-container *ngIf="request.status !== 'Aceptada'; else acceptedRequest">
      <div class="company-card flex-row center">
        <company-logo class="logo" [company]="request.sender"></company-logo>
        <company-info [company]="request.sender" [compact]="true"></company-info>
      </div>
      <i class="material-symbols-rounded arrow">play_arrow</i>
      <div class="detail-card">
        <quantity-control [quantity]="request.volume" [compact]="true"></quantity-control>
      </div>
      <strong> a </strong>
      <div class="detail-card">
        <price-display [price]="request.price" [market]="company.market" [withoutYear]="true">
        </price-display>
      </div>
      <i class="material-symbols-rounded">play_arrow</i>
      <div class="company-card flex-row center">
        <company-logo class="logo" [company]="request.receiver"></company-logo>
        <company-info [company]="request.receiver" [compact]="true"></company-info>
      </div>

    </ng-container>

    <ng-template #acceptedRequest>
      <div class="flex-row center">
        <div class="detail-card">
          <quantity-control [quantity]="request.volume" [compact]="true"></quantity-control>
        </div>
        <strong> a </strong>
        <div class="detail-card">
          <price-display [price]="request.price" [market]="company.market" [withoutYear]="true">
          </price-display>
        </div>
      </div>

      <div class="accepted">
        <strong>{{ request.status }}</strong>
        <i class="material-symbols-rounded">check_circle</i>
      </div>
    </ng-template>
  </div>

</div>
