import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { Pagination, parsePagination } from "../../../../../models/pagination.model";
import { FilterSetComponent } from "../../../../../ui/components/filter-set/filter-set.component";
import { TableFilters } from "../../../../../utilities/table-filters";
import { Waybill } from "../../models/waybill.model";
import { WaybillService } from "../../services/waybill.service";

@Component({
  selector: "waybills-table",
  templateUrl: "./waybills-table.component.html",
  styleUrls: ["./waybills-table.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WaybillsTableComponent),
      multi: true,
    },
  ],
})
export class WaybillsTableComponent extends TableFilters implements OnInit, OnDestroy, ControlValueAccessor {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output("load") readonly onLoad = new EventEmitter();

  public waybills: Waybill[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public predefinedFilters: boolean;

  constructor(
    private waybillService: WaybillService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
    // this.loading = true;
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;

    if ((configuration.imported_data.collections &&
      configuration.imported_data.collections.includes("waybills"))) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    this.subscriptions.push(this.waybillService.get(this.company.id, this.filters).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  private dataLoaded(data: Waybill[], pagination?: Pagination): void {
    this.waybills = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.waybills,
      pagination: pagination
    });
  }

  // ngModel
  private _value: Waybill[] = [];
  public get selected(): Waybill[] {
    return this._value;
  }
  public set selected(v: Waybill[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
